import getConfig from 'next/config';

import light from './themes/light';

const { publicRuntimeConfig } = getConfig();

const { API_URL } = publicRuntimeConfig;
export const apiUrl = String(API_URL);

const happier = '/controle';

const contrate = '/contrate';

const siteConfig = {
  siteName: 'Happier',
  siteIcon: '',
  logo: '/static/svgs/logo.svg',
  logoWhite: '',
  gaps: '/dashboard',
  footerText: `© Copyright ${new Date().getFullYear()} Happier. Todos os direitos reservados.`,
};

const themeConfig = {
  current: light,
};

const language = {
  languageId: 'portuguese',
  locale: 'pt',
  text: 'Português',
};

const langDir = 'ltr';

const apiUrls = {
  login: apiUrl + '/auth/login',
  logout: apiUrl + '/auth/logout',

  states: apiUrl + '/v2/estados',
  cities: apiUrl + happier + '/v2/cidades',
  cnpj: apiUrl + happier + '/v2/cnpj',
  definitions: apiUrl + '/definicoes',
  cep: apiUrl + happier + '/v2/cep',
  verifyEmail: apiUrl + happier + '/emailcadastrado',
  verifyCnpj: apiUrl + happier + '/verificar-cnpj',

  googleAuth: apiUrl + '/auth/oauth',
  googleAuthCallback: apiUrl + '/auth/google/callback',
  googleGetTokenData: apiUrl + '/auth/google/getdata',

  errors: apiUrl + happier + '/erros',
  comments: apiUrl + happier + '/erros/comentario',
  results: apiUrl + happier + '/resultados',
  goals: apiUrl + happier + '/metas',
  upsell: apiUrl + happier + '/upsell',
  upsellRecursos: apiUrl + happier + '/upsell-recursos',
  downsell: apiUrl + happier + '/downsell',
  downsellRecursos: apiUrl + happier + '/downsell-recursos',
  churn: apiUrl + happier + '/churn',
  churnReason: apiUrl + happier + '/motivochurn',
  income: apiUrl + happier + '/receita',
  exempt: apiUrl + happier + '/cobrancas-isentadas',
  newClients: apiUrl + happier + '/novos-clientes',
  nps: apiUrl + happier + '/nps',
  indications: apiUrl + happier + '/indicados',
  indication: apiUrl + happier + '/indicacao',
  plans: apiUrl + happier + '/planos',
  plan: apiUrl + happier + '/plan',
  createPlanShareLink: apiUrl + happier + '/plan/link',
  sendEmailPlanLink: apiUrl + happier + '/plan-send/sendlink',
  groupPlansList: apiUrl + happier + '/plangroup',
  unlinkPlan: apiUrl + happier + '/plangroup/desvincular',
  activePlansList: apiUrl + happier + '/sistemaplano',
  planSituation: apiUrl + happier + '/situacao-plano',
  resources: apiUrl + happier + '/recursos',
  groups: apiUrl + happier + '/grupos',
  inactivateGroup: apiUrl + happier + '/groupsinactivate',
  activateGroup: apiUrl + happier + '/groupsactivate',
  partnersTypes: apiUrl + happier + '/tipoparceiro',
  invitePartner: apiUrl + happier + '/indicar',
  partners: apiUrl + happier + '/company',
  updateClient: apiUrl + happier + '/clientes',
  clients: apiUrl + happier + '/usuarios',
  client: apiUrl + happier + '/usuario',
  partnersClientsList: apiUrl + happier + '/usuarios-parceiro',
  inactivateClient: apiUrl + happier + '/clientesinativar',
  activatePartner: apiUrl + happier + '/clientesativar',
  emails: apiUrl + happier + '/emails',
  findClients: apiUrl + happier + '/associar/clientes',
  linkClients: apiUrl + happier + '/parceiro/associar',
  unblockPartner: apiUrl + happier + '/clientesdesbloquear',
  blockPartner: apiUrl + happier + '/clientesbloquear',
  exemptCharge: apiUrl + happier + '/cobranca/acao',
  undoExemptCharge: apiUrl + happier + '/cobranca/desfazer-isencao',
  charges: apiUrl + happier + '/cobrancas',
  donwloadNfe: apiUrl + happier + '/cobranca/nota',
  downloadCharge: apiUrl + happier + '/boleto',
  newCharge: apiUrl + happier + '/cobranca/segunda-via',
  separateCharge: apiUrl + happier + '/cobrancas/gerar-cobranca-avulsa',
  deposition: apiUrl + happier + '/depoimentos',
  observation: apiUrl + happier + '/observacoes',
  dashboard: apiUrl + happier + '/dashboard',
  graphic: apiUrl + happier + '/grafico',
  news: apiUrl + happier + '/novidades',
  notifications: apiUrl + happier + '/notificacoes',
  setup: apiUrl + happier + '/setup',
  plansGroups: apiUrl + happier + '/planos-e-grupos',
  buscaPaga: apiUrl + happier + '/busca-paga',
  errosResumidos: apiUrl + '/controle/erros-resumo',
  cobrancaPrePaga: apiUrl + '/controle/faturas/gerar-individual-pre-pago',
  empresas: apiUrl + '/controle/empresas',

  // Contrate
  planosEGrupos: apiUrl + contrate + '/planos-e-grupos',
};

export { siteConfig, themeConfig, language, langDir, apiUrls };
